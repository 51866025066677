@use 'styles/globals/breakpoints';
@use 'styles/globals/mixins/font';


%flex-full-width-footer{
    display: flex;
    width: 100vw;
    flex: 1 0 auto;
}

.block{
    @extend %flex-full-width-footer;
    background-color: #fafafa;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

    @media print{
        display: none !important;
    }
}

// top
.upper{
    display: flex;
    border-top: 1px solid var(--dove);
    border-bottom: 1px solid var(--dove);
    padding: 2rem 4vw;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    max-width: var(--standard-width);
    width: 100%;
    background-color: var(--white);

    @media screen and (max-width: breakpoints.$screen-sm){
        flex-wrap: wrap;
    }
}

.logoCntr{
    width: 20%;
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width: breakpoints.$screen-md){
        width: 50%;
        flex: 1 0 auto;
    }

    @media screen and (max-width: breakpoints.$screen-sm){
        width: 100%;
        flex: 1 0 auto;
        margin-bottom: 4rem;
    }
}

.itemList{
    margin: 0;
    padding: 0;
    list-style: none;
}


.logo{
    @media screen and (max-width: breakpoints.$screen-sm){
        width: 100%;
    }
}

.callOut{
    display: flex;
    background-color: transparent;
    color: inherit;
    align-items: center;
    width: 20%;

    &:hover{
        color: inherit;
    }

    @media screen and (max-width: breakpoints.$screen-md){
        justify-content: center;
    }

}


.callText{
    margin-left: 1rem;
    line-height: 1.2;   
    @include font.dynamic-font(13, 16);
    align-content: flex-start; 
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: breakpoints.$screen-md){
        display: none;

        &__Chat{
            display: block;
        }
    }
}


// mid
.main{
    padding: 4rem 4vw;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    max-width: var(--standard-width);
    width: 100%;

    @media screen and (max-width: breakpoints.$screen-md){
        padding: 0 4vw;
        flex-wrap: wrap;
    }

    @media screen and (max-width: breakpoints.$screen-xs){
        padding: 0;
        padding-bottom: 2rem;
    }
}

.list{
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &[open]{
        .itemHeadingParent{
            &::after{
                content: "";
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M19 11h-6v-6c0-0.6-0.4-1-1-1s-1 0.4-1 1v6h-6c-0.6 0-1 0.4-1 1s0.4 1 1 1h6v6c0 0.6 0.4 1 1 1s1-0.4 1-1v-6h6c0.6 0 1-0.4 1-1s-0.4-1-1-1z'%3E%3C/path%3E%3C/svg%3E");            
                transform: rotate(45deg);
            }
        }
    }


    @media screen and (max-width: breakpoints.$screen-md){
        width: 50%;
        padding: 2rem 0;
    }
    

    @media screen and (max-width: breakpoints.$screen-sm){
        width: 100%;
        border-bottom: 1px solid #ccc;
        padding: 0 2rem;
    }
}

.listContact{
    @media screen and (max-width: breakpoints.$screen-md){
        border-bottom: none;
    }

    @media screen and (max-width: breakpoints.$screen-sm){
        padding: 2rem;
    }
}

.itemCntr{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
}

.item{
    padding: 1rem 0;
    line-height: 1;
    cursor: pointer;
    list-style: none;

    &::-webkit-details-marker{
        display: none !important;
    }
    

    @media screen and (max-width: breakpoints.$screen-md){
        padding: 0;
    }

    &Social{
        display: flex;
        flex: 1 0 auto;
        margin-top: 4rem
    }  

    &SocialLinks{
        justify-content: space-between;
        align-items: flex-start;
        align-content: flex-start;
        display: flex;
        flex: 1 0 auto;
        width: 175px;

        @media screen and (max-width: breakpoints.$screen-sm){
            margin-top: 1rem;
            width: 50vw;
        }
    }

    &Heading{
        // padding: 1.5em 0;
        line-height: 1;
        letter-spacing: 0.3rem;
    }

    &HeadingParent{
        line-height: 1;
        background-color: transparent;

        @media screen and (max-width: breakpoints.$screen-sm){
            display: flex;
            align-content: center;
            align-items: center;
            width: 100%;
            flex: 1 0 auto;

            &::after{
                content: "";
                width: 20px;
                height: 20px;
                display: block;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M19 11h-6v-6c0-0.6-0.4-1-1-1s-1 0.4-1 1v6h-6c-0.6 0-1 0.4-1 1s0.4 1 1 1h6v6c0 0.6 0.4 1 1 1s1-0.4 1-1v-6h6c0.6 0 1-0.4 1-1s-0.4-1-1-1z'%3E%3C/path%3E%3C/svg%3E");            
            }

           
        }

    }
}

.listHeading {
    margin: 0 0 1.2rem 0;
    font-weight: 800;
    font-size: 1.8rem;
    line-height: 1;
    text-align: left;
    letter-spacing: 0.3rem;
    text-transform: uppercase;
    color: var(--primary-color) !important;

    @media screen and (max-width: breakpoints.$screen-md){
        line-height: 1.4;
        margin: 0;
        width: 100%;
    }

    @media screen and (max-width: breakpoints.$screen-sm){
        margin: 0;
        padding: 2rem 0;
    }

}

.listLink{
    font-size: 1.6rem;
    line-height: 1.4;
    color: var(--primary-color) !important;


    @media screen and (max-width: breakpoints.$screen-md){
        padding: 1rem 0;
        width: 100%;
        display: flex;
    }

    &:hover {
        color: var(--primary-color) !important;
        text-decoration: underline;
    }
}

.listText{
    @extend .listLink;
    display: block;

    &:hover{
        text-decoration: none !important;
    }
}


// bottom
.lower{
    background-color: #f2f2f2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lowerWrapper{
    padding: 2rem 4vw;
    align-items: center;
    display: flex;
    max-width: var(--standard-width);
    width: 100%;
}

.seal{
    margin-right: 4rem;

    @media screen and (max-width: breakpoints.$screen-md){
        margin-right: 2rem;
        width: 30%;
    }

    & img{
        object-fit: contain;
    }
}

.legal{
    display: flex;
    flex-direction: column;

    @media screen and (max-width: breakpoints.$screen-md){
        width: 100%;
    }
}

.legalCopy{
    @include font.dynamic-font(12, 14);
}

