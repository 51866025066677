@use 'styles/globals/variables' as *;
@use 'styles/globals/placeholders' as *;


.block{
    display: flex;
    flex-direction: column;
}

.row{
    display: flex;
    flex-direction: column;
}

.promoCodeForm{
    margin: 0 0 3rem 0;
    display: flex;
    flex-direction: column;
    box-shadow: $box-shadow-sm;
    padding: 2rem;
    background-color: $white;
}

.controlGroup{
    position: relative;
    display: flex;
    margin-bottom: 2rem;
}

.applyBtn{
    height: 56px;
}

.cancelBtn{
    position: absolute;
    top: 50%;
    margin-top: -18px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    background-color: transparent;
    justify-content: center;
    align-content: center;
    border-radius: 50%;

    & svg{
        margin: 0;
    }

    &:hover{
        & svg{
            fill: inherit;
        }    
    }
}

.toggle{
    display: flex;
    align-items: center;
    align-content: center;
    line-height: 1;
    justify-content: flex-start;
    margin-bottom: 1rem;
    color: $link_color;
}

.toggleText{
    margin-left: 0;
    font-size: 1.8rem;
}

.toggleIcon{
    fill: $ocean;
    margin-right: 1rem;
}

.control{
    & label{
        display: none;
    }

    & input{
        border: none;
        height: 59px;
    }
}

.appliedCntr{
    display: flex;
    margin: 2rem 0 0 0;
    justify-content: space-between;
    align-items: center;
}

.appliedLabel{
    line-height: 1.3;
}

.help{
    padding: 1rem 0 0 0;
    width: 100%;
    line-height: 1.3;
    color: $terracotta !important;
}

