@use 'styles/globals/breakpoints';



.block{
    display: inline-block;
    flex-direction: column;
    width: 100%;

    @media only screen and (max-width: breakpoints.$screen-md) {
        width: 100vw;
    }
}

// .heading{
//     margin-left: 1rem;

//     @media only screen and (max-width: breakpoints.$screen-md) {
//         margin-left: 0;
//     }
// }
