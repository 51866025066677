@use 'styles/globals/breakpoints';

.block{
    padding: 0;

    @media only screen and (max-width: breakpoints.$screen-md ) {
		padding: 0 2rem;
	}

    @media only screen and (max-width: breakpoints.$screen-xs ) {
		padding: 0 8vw;
	}

	& img{
		object-fit: contain;
		width: 100%;
	}

}
