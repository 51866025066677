@use 'styles/globals/placeholders' as *;
@use 'styles/globals/breakpoints';


.block{
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    background-color: transparent;
    border: 1px solid #eee;
    border-radius: var(--border-radius);
    margin: 0;
    height: 100%;
    color: var(--primary-color);
    cursor: pointer;

    &:hover{
        background-color: #f9f9f9;
    }

    strong{
        display: block;
        line-height: 1;
        margin-top: 0.5rem;
    }

    small{
        display: block;
        color: #777;
        font-size: 1rem;
        font-weight: normal;
    }

    @media screen and (max-width: breakpoints.$screen-sm){
        padding: 1rem;
        min-height: 100px;
    }
}

.item{
    margin-right: 1rem;

    &:last-child{
        margin-right: 0;
    }

    @media screen and (max-width: breakpoints.$screen-sm){
       margin: 0 0 1rem 0;
    }
}


.period{
    display: block;
    font-size: 1.4rem
}

.price{
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1;
    margin-top: 1rem;
    display: flex;
    width: 100%;
}

.control{
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.selected{
    background-color: #f9f9f9;
    border-color: var(--terracotta);
}

