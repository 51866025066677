@use 'styles/globals/breakpoints';


.block{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 8vw;

    @media screen and (max-width: breakpoints.$screen-md){
        padding: 0 2rem;
        margin: 0;
    }
}

.wrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
}

.label{
    font-size:17px !important;
    color: var(--ocean);
}

.description{
    padding: 0 10rem;

    @media screen and (max-width: breakpoints.$screen-md){
        padding: 0;
    }

}

.controlGroup{
    margin: 0 0 4rem 0;
    padding: 0 2rem 0 0;

    @media screen and (max-width: breakpoints.$screen-md){
        padding: 0;
        width: 100%;
    }
}

.buttonPad {
    padding-right: 2rem !important;
}

.error { 
    color: red !important;
}

