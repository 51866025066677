@use 'styles/globals/breakpoints';


.block{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
}

.logoOcean{
    width: 300px;
}

.button{
   height: 60px;
   width: 100% !important; 
}

.googleButton {
    height: 60px;
    width: auto;
}

 .googleLoading {
    background-color: var(--ice25) !important;
 }
 
.header{
    display: flex;
    align-items: center;
    align-content: center;
    margin: 0;
}

@media (max-width: breakpoints.$screen-sm) {
    .googleButton {
            width: 100%;
            margin-left: 0;
        }        
    .sm_padding{
        padding-left: 2rem !important;
        padding-right: 2rem !important;

    }

    .header{
        margin-top: 1rem;
    }

    .logoOcean{
        width: 200px;
    }

    .authForm{
        padding: 0 2rem;
    }

    .authForm span form fieldset legend{
        font-size: 2rem !important;
    }
}

