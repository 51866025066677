

.block{
    display: block;
    width: 100%;
    position: relative;

    & figure img{
        inset: 0;
    }
}


