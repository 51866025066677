@use 'styles/globals/breakpoints';


.element{
    order: 0;
    justify-content: center;

    [class*="PodSlider_block"]{
        &::after, &::before {
            display: none;
        }
    }

    @media only screen and ( max-width: breakpoints.$screen-md ) {
        order: 1;
    }
}

.imgCntr{
    order: 1;
    padding-left: 4rem;

    @media only screen and ( max-width: breakpoints.$screen-md ) {
        order: 0;
        padding-left: 0;
    }
}


.image{
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media only screen and ( max-width: breakpoints.$screen-lg ) {
        width: auto;
        height: 100%;
    }
}

