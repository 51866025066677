

.block{
    color: var(--ocean);
    display: flex;
    align-items: center;
    align-content: center;
    padding: 1rem 2rem;
    margin: 0;
    background-color: var(--ice50);
    box-shadow: var(--box-shadow-sm);
}

.text{
    line-height: 1.3;
}

.discountIcon{
    margin-right: 1rem;
    transform: rotate(180deg);
}

