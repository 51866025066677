@use 'styles/globals/placeholders' as *;


.heading{
    @extend %shipping_heading;
    margin: 0 !important;
}

.block{
    display: flex;
    align-items: flex-start;
    padding: 0 0 2rem 0;
    position: relative;
    margin-bottom: 1rem;

    & svg{
        margin-top: -8px;
    }
}

.icon{
    @extend %shipping_icon;
}

.chatBtn{
    display: contents;
    color: var(--link_color);
    text-decoration: underline;
    font-weight: 800;
}

.tooltip{
    position: absolute;
    top: 6px;
    right: -30px;
}

.cta{
    font-weight: 800;
    text-decoration: underline;
}
