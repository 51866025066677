// Supports both Blog and Buying Guide Pages
@use 'styles/globals/breakpoints';
@use 'styles/globals/mixins/font';
@use 'styles/globals/placeholders' as *;


.block{
    display: flex;
}


.wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.sectionWrapper{
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: breakpoints.$screen-lg) {
        flex-direction: column;
    }
}

.layoutBlock{
    text-align: center;
    padding: 4rem 0;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        padding: 7rem 9rem 4rem;
        text-align: left;
    }
}

.heroImage{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    height: 100%;

    & > img{
        object-fit: cover !important;
    }

    @media only screen and (max-width: breakpoints.$screen-sm ) {
        position: static;

    }
}

.leftColumn{
    width: 25%;
    display: flex;
    flex-direction: column;
    padding-right: 6rem;

    @media only screen and (max-width: breakpoints.$screen-lg ) {
		width: 100%;
        padding: 0 2rem;
        order: 2
	}
}


.leftColumnLogo{
    width: 100%;
    margin-bottom: 4rem;
}

.article{
    width: 75%;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: breakpoints.$screen-xl ) {
        & [class*="ImageContentRow_block"]{
            flex-direction: column;
        }

        & [class*="ImageContentRow_contentWrapper"], [class*="ImageContentRow_image"]{
            width: 100%;
        }

        & [class*="ImageContentRow_content"]{
            position: relative;
            height: auto;
            padding: 2rem 0 0 0;
        }
    }

    @media only screen and (max-width: breakpoints.$screen-lg ) {
		width: 100%;
        padding: 0 2rem;
        order: 1;
	}
}



// blog home page
.heroCallout{
    background-color: var(--white);
    display: flex;
    width: 100%;
    z-index: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        padding: 2rem 2rem 0 2rem;
    }
}

.homeHeader{
    width: 100%;
    padding-top: calc((400px/1560px) * 100%);
    position: relative;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        padding-top: 0;
        margin: 0;
    }
}

.heroLogo{
    width: 25%;
    justify-content: center;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100%;
    }
}

.logoLink{
    display: block;
}

.headerSearch{
    width: 100%;
    padding: 0 2rem;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        margin-top: 4rem;
        width: 100%;
    }
}

.paginationCntr{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.paginationWrapper {
    display: flex;
    justify-content: center;
    padding: 2rem 0 1rem;
}

.searchResultTitle{
    border-bottom: none;
    display: flex;
    margin-bottom: 0.5rem;
}

.searchResultsUrl{
    display: block;
    margin-bottom: 1.5rem;
    color: #777;
}

.searchCntr{
    display: flex;
    justify-content: space-between;
}

.searchResults{
    width: 60%;
}

.searchCol{
    width: 30%;
}

.cardTitle {
    color: var(--ocean) !important;
    text-transform: none !important;
    font-size: 3rem !important;
    letter-spacing: 0 !important;
    border-bottom: 2px solid var(--ocean);
    width: max-content !important;
    margin: 0 auto;

    @media only screen and (max-width: breakpoints.$screen-lg) {
        font-size: 2.2rem !important;
    }

    // @media only screen and (max-width: breakpoints.$screen-md) {
    //     font-size: 1.4rem !important;
    // }

}

.cardBlock{
    display: flex;
    flex-direction: row;
    position: relative;
}

.row{
    display: flex !important;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: row !important;
    width: 100%;

    & > *{
        width: 25%;
        max-width: 25%;

        @media only screen and (max-width: breakpoints.$screen-md ) {
            max-width: 25%;
            width: 25%;
            flex: 1 0 25% !important;
        }

        @media only screen and (max-width: breakpoints.$screen-sm ) {
            max-width: 50%;
            width: 50%;
            flex: 1 0 50% !important;
        }
    }


    @media only screen and (min-width: breakpoints.$screen-lg ) {
        justify-content: center;
    }

    @media only screen and (max-width: breakpoints.$screen-md ) {
        justify-content: center;
    }

    @media only screen and (max-width: breakpoints.$screen-xs ) {
        flex-direction: row;
        justify-content: flex-start;
    }
}

.recentHeader {
    padding: 1rem 0 0;

    @media only screen and (max-width: breakpoints.$screen-md) {
        display: none;
    }
}

.search {
    border: 2px solid var(--ocean) !important;
}

.blogLayoutWrapper {
    display: flex;
    flex-direction: column;
}

.textOrder {
    order: 1;

    @media only screen and (max-width: breakpoints.$screen-md) {
        order: 3;
    }
}

.categoryOrder {
    order: 2;
    
    @media only screen and (max-width: breakpoints.$screen-md) {
        order: 2;
    }
}

.searchOrder {

    padding: 6rem 0;
    order: 3;

    @media only screen and (max-width: breakpoints.$screen-md) {
        order: 1;
    }
}

.categoryLinks:hover {
    text-decoration: none !important;
}

.visuallyHidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

