@use 'styles/globals/placeholders' as *;


.block{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 0;
    border-bottom: 2px solid var(--dove);
    margin-bottom: 2rem;
}

.loadingPlans{
    display: flex;
    margin-bottom: 2rem;

    & div{
        margin-right: 1rem;
    }
}

.attachedTextLoader{
    margin-top: 0.5rem !important;
}

.attachedLoader{
    width: 100%;
    display: flex;
}

.attachedLoaderText{
    display: flex;
    width: 70%;
    flex-direction: column;
    margin-left: 2rem;
}

