

.block{
    width: 100%;
    position: relative;

    [class*="Picture_fill"]{
        height: auto;
    }
}

.image{
    width: 100% !important;
    object-fit: contain;
    height: auto;
}

.ctaBlock{
    text-align: center;
}

.link{
    display: block;   
}

