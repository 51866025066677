@use 'styles/globals/breakpoints';
@use 'styles/globals/placeholders' as *;

.block{
    position: relative;
    width: 100%;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;

    &__select{
        &::after{
            @extend %dark-arrow;
            transform: rotate(90deg);
            right: 1.5rem;
        }
    }
}

.errorIcon{
    position: absolute;
    width: 30px;
    height: 100%;
    right: 70px;
    top: 0;
    fill: var(--terracotta);
}

.flushRightIcon{
    right: 20px
}

.error{
    &__label{
        color: var(--terracotta) !important;
    }

    &__control{
        border-color: var(--terracotta) !important;
    }

    &__help{
        color: var(--terracotta) !important;
    }
}

.hasUnit{
    width: auto;
}

.label{
    @extend %standard-control-label;
    width: calc(100% - 4.1rem) !important
}



.active {
    @extend %standard-active-control;

    & [type=search] {
         & ~ label {
             padding: 0 2rem ;
             top: -8px !important;
             left: 15px !important;
             width: max-content;
        }
    }

    & label{
        &::after {
            opacity: 0;
        }
    }
}





.controlGroup{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.file{
    margin-top: 20px;;
   @extend %standard-active-control;
    .label{
        font-size:17px !important;
        color: var(--ocean);
    }
    .control{
        margin-left: 10px;
        margin-top: 20px;
    }
}

.control{
    @extend %standard-control;

    &[type=search]{
        padding-left: 4rem;
        padding-right: 1rem;

        & ~ label{
            top: 16px;
            left: 4rem;
        }

        @extend %search-cancel-button;
    }

    &::-webkit-inner-spin-button{
        display: none;
    }
}

.togglePassword{
    position: absolute;
    right: -5px;
    top: 0;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: transparent;
    color: var(--ocean);

    &::after{
        content: "";
        width: 100%;
        border-bottom: 1px solid var(--ocean);
        position: absolute;
        bottom: 30%;
        left: 0;
    }
}

.clearInput{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    max-height: 55px;
    width: 50px;
    padding: 1rem;
    margin: 0 1rem;
    background-color: transparent;

    & svg{
        fill: #aaa;
    }
}


.help{
    @extend %standard-control-help-text;
}

.searchIcon{
    position: absolute;
    left: 12px;
    width: 25px;
    top: 13px;
    fill: #ddd;
}

.postFix{
    position: absolute;
    width: auto;
    line-height: 1;
    right: 1rem;
    top: 50%;
    margin-top: -5px;
    color: var(--ocean);
}

@media (max-width: breakpoints.$screen-xl) {
        .togglePassword {
                right: -35px;
        }
}

