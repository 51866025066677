@use 'styles/globals/placeholders' as *;
@use 'styles/globals/breakpoints';
@use 'styles/globals/mixins/font';


.modal{
    background-color: var(--ice50);
    padding: 0 !important;
    min-width: 800px;
    position: relative;

    @media screen and (max-width: breakpoints.$screen-md){
        width: 85svw !important;
        max-height: 80svh !important;
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: 400px;
    }

    @media screen and (max-width: breakpoints.$screen-xs) {
        max-width: 350px !important;
    }
}

.content{
    width: 100%;
    min-width: 413px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 1rem 2rem;
    text-align: center;

    @media screen and (max-width: breakpoints.$screen-md){
        order: 1;
        width: 100%;
    }

    @media screen and (max-width: breakpoints.$screen-xs) {
        max-width: 350px !important;
        min-width: 350px;
        margin: auto;
        padding: 2rem 0;
    }
}

.image{
    width: 100%;
    //max-width: 387px !important;
    min-width:387px !important;
    height: 580px !important;
    object-fit: cover !important;
    overflow: hidden;

    @media screen and (max-width: 568px) {
       height: auto !important;
    }

    @media screen and (max-width: breakpoints.$screen-md){
        order: 0;
        max-width: 100% !important;
    }
}

.form{
    width: 100%;
    margin-top: 1rem;
}

.text{
    font-size: 1.4rem;
    margin: 1rem 0;

    @media screen and (max-width: breakpoints.$screen-md){
        font-size: 1.6rem;
        margin: 1rem 0 0 0;
    }
}

.block{
    display: flex;
    flex-direction: row;

    @media screen and (max-width: breakpoints.$screen-md){
        flex-direction: column;
    }
}

.logo{
    width: 150px;
}

.heading{
    @extend %h3;
    font-size: 2.1rem;
    margin: 2rem 0 1rem 0;

    @media screen and (max-width: breakpoints.$screen-md){
        font-size: 1.8rem;
    }
}

.callOut{
    @extend %h1;
    font-size: 4rem;
    margin: 0 0 0rem 0;
    color: var(--ocean);
    letter-spacing: 3px !important;

    @media screen and (max-width: breakpoints.$screen-md){
        margin: 0;
        line-height: 1;
    }
}

.callOutPhone{
    @extend %h1;
    font-size: 3.7rem;
    margin: 0 0 0rem 0;
    color: var(--ocean);
    letter-spacing: 0.32rem;

    @media screen and (max-width: breakpoints.$screen-md){
        margin: 0;
        line-height: 1;
    }
}

.closeBtn{
    position: absolute;
    top: 0;
    right: 0;
    padding: 2rem;
    line-height: 1;
    background-color: transparent;
    z-index: 9;
    // box-shadow: var(--box-shadow;

    @media screen and (max-width: breakpoints.$screen-md){
        padding: 1rem;
    }
}

.control{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 2rem;

    & [class*="Radio_block"]{
        width: auto !important;
        flex: 0 0 auto !important;

        & label{
            flex: 0 0 auto !important;
            font-size: 1.2rem;
        }
    }

    @media only screen and (max-width: breakpoints.$screen-md) {
        justify-content: center;
        padding: 0;
        margin-bottom: 0;
        font-size: 1rem;
    }
}

.controlSwitch{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
    margin-bottom: 1rem;

    & [class*="Radio_block"]{
        width: auto !important;
        flex: 0 0 auto !important;

        & label{
            flex: 0 0 auto !important;
            font-size: 1.2rem;

            @media screen and (max-width: breakpoints.$screen-md){
                font-size: 1rem;
            }
        }
    }
}

.controlButton{
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 1rem;

    & [class*="Radio_block"]{
        width: auto !important;
        flex: 0 0 auto !important;

        & label{
            flex: 0 0 auto !important;
            font-size: 1.2rem;

            @media screen and (max-width: breakpoints.$screen-md){
                font-size: 1rem;
            }
        }
    }
}

.submitBtn{
    height: 56px !important;
    width: 100% !important;
    text-transform: none !important;
}

.noThanks {
    background-color: #fff;
    color: var(--ocean);
    border-bottom: 1px solid var(--ocean);
    font-size: 12px;

    @media only screen and (max-width: breakpoints.$screen-md) {
        font-size: 14px !important;
    }
}

.terms {
    font-size: 10px;
    text-align: center;
    padding-top: 1.5rem;;

    @media only screen and (max-width: breakpoints.$screen-md) {
        font-size: 12px;
    }
}

.smsButton {
    @media screen and (max-width: breakpoints.$screen-md) {
        display: flex;
        width: 65%;
        align-items: center;
        justify-content: space-between;
        background: white;
        border: 1px solid var(--ocean);
        padding: 12px 16px;
        border-radius: 8px;
        text-decoration: none;
        font-size: 1rem;
        color: var(--navy);
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);

    }
}

.smsText {
    text-align: left;
}

.smsIcon {
    width: 24px;
    height: 24px;
    fill: var(--charcoal);
}