@use 'styles/globals/breakpoints';
@use 'styles/globals/mixins/font';


.block {
    display: flex;
    position: relative;
    z-index: 1;
    min-height: 600px;
    height: auto;
    min-width: 25%;

    @media only screen and (max-width: breakpoints.$screen-md) {
        padding: 2em;
        min-height: 550px;
    }
}


.recommBlock{
    min-height: auto;
    flex-direction: column;

    @media only screen and (max-width: breakpoints.$screen-md) {
        min-height: 350px;
        padding: 0;
    }
}


.linkBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 0;
    color: inherit;

    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

.cntr {
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 0 1rem;
}

.img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.imgPrimary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-height: 300px;
    background-position: center center;
    z-index: -1;
    background-color: var(--white);
}


.body {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex: 1 0 auto;
    position: relative;
    z-index: 1;
    padding: 2rem 1rem 0 1rem;
}

.titleLink {
    text-align: left;
    color: inherit;
    margin: 0;
    font-weight: 400;
    text-transform: capitalize;
    @include font.dynamic-font(12, 14);
    line-height: 1.3;

    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

.titleBlock {
    display: flex;
    flex-direction: column;
    min-height: 40px;
}

.brandLInk {
    font-size: 1.4rem;
    margin: 0;
}

.price {
    flex: 1 0 auto;
    text-align: left;
}

