@use 'styles/globals/breakpoints';


.block {
    @media (max-width: breakpoints.$screen-xl) {
        display: flex !important;
        flex-direction: column !important;
    }
}

