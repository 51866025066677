
.recentList{
    width: 100%;
    display: flex;
    list-style-type: none;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 0 0 0;
}

.recentItem{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: auto;
    // width: 50%;
    // min-height: 480px;
    overflow: hidden !important;
    padding: 2rem;


    &:hover{
        text-decoration: none !important;
    }

}

.textBlock{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.recentBlurb{
    color: initial;
    // margin: 2rem 0 0 0;
    // min-height: 80px;
}

.psedoLink{
    text-decoration: underline;
    color: var(--ocean);
}

.categoryName{
    margin: 2rem 0 1rem 0;
    color: initial;
}

.image {
    object-fit: cover;
    overflow: hidden;
    aspect-ratio: 16/9;
    width: 100%;
    height: 350px !important;

    & img {
        object-fit: cover;
        overflow: hidden;
        aspect-ratio: 16/9;
        width: 100%;
        height: 350px !important;
    }
}

