

.block{
    display: flex;
    flex-direction: column;
    padding-bottom: 4rem;
}

.navItem{
    display: flex;
    flex-direction: column;
    color: #777;
    padding: 2rem 2rem 2rem 4vw;
    border-bottom: 1px solid var(--dove);
    border-top: 1px solid var(--white);
    cursor: pointer;
    width: 100%;

    &:hover{
        text-decoration: none;
        color: inherit;
    }

    &:first-of-type{
        border-top: none
    }

    &:last-of-type{
        border-bottom: none
    }
}

.activeNavItem{
    position: relative;
    color: var(--charcoal);

    & .navSubTitle{
        color: var(--charcoal);
    }

    &::after{
        content: "";
        position: absolute;
        right: -40px;
        border-right: 30px solid var(--white);
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
        top: 50%;
        margin-top: -30px;
    }
}


.navTitle{
    margin: 0;
}

.navSubTitle{
    color: #888;
}


