

.block{
    width: 100%; 
    height: 100%; 
    object-fit: contain;
    object-position: left center;
    max-width: var(--standard-width);
    margin-bottom: 2rem;
}

.link{
    display: block;
}
