@use '/styles/globals/placeholders' as *;
@use 'styles/globals/mixins/font';


.block{
    display: flex;
    flex-direction: column;
}

.heading{
    @include font.dynamic-font(16, 18);    
    margin-bottom: 2rem;
}

.options{
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: relative;
}

.loaderRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
}

.itemCoverage{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    list-style: none;
    margin: 0 0 2rem 0;
}

.itemCoverageItem{
    font-size: 1.4rem;
    line-height: 1.6;
}


.editSwatch{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.link{
    text-decoration: underline;
    @include font.dynamic-font(14, 18);
    color: var(--link_color);
    background-color: transparent;
}
