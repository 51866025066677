
.label{
    color: var(--indigo);
}

.radio{
    width: 100px !important;
    display: inline-block;
}

.row{
    width: 100%;
    margin-bottom: 1rem;
}

.link{
    background-color: transparent;
    color: var(--link_color);
    text-decoration: underline;
}

.legal{
    margin-top: 2rem;
    text-align: center;
    line-height: 1.6;
}

