@use 'styles/globals/breakpoints';

.block{
    background-color: var(--indigo);
    padding: 0 25vw 6rem 25vw;
    margin-bottom: 5rem;
    text-align: center;
    width: 100%;

    @media (max-width: breakpoints.$screen-md) {
        padding: 6rem 2rem;
    }
}

.heading{
    margin-bottom: 0 !important;
    text-align: center;
    color: var(--white) !important;
}

.breadcrumb{
    background-color: var(--indigo);
    color: var(--white);

    & [class*="Breadcrumb_link"]{
        color: var(--white);
    }
}

.subHeading{
    font-size: 2.1rem;
    margin-bottom: 3rem;
    color: var(--white);
}

.headingLink{
    color: inherit;
}

.topicBlock{
    padding: 2rem;
    margin: 0 0 4rem 0;
    list-style-type: none;
}

.topic{
    margin-bottom: 1.4rem;
}

.column{
    columns: 2;
}

