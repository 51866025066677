
.block{
    width: auto;
    display: flex;
    justify-content: flex-end;
    border: 0;
    margin: 2rem 0;
    padding: 0;
    direction: rtl;

    & > .widgetLabel:hover{
        color: var(--star-orangebackground);
    }

    & > .widgetLabel:hover ~ .widgetLabel{
        color: var(--star-orangebackground);
    }

    & > .widgetLabel.active ~ .widgetLabel{
        color: var(--star-orangebackground);
    }
}


.widgetLabel{
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 10%;
    cursor: pointer;
    font-size: 6rem;
    float: right;
    color: #ccc;
    line-height: 1;
    margin: 0 2rem;

    &:last-child{
        margin-left: 0;
    }
}

.active{
    color: var(--star-orangebackground);
}

.widgetScore{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

