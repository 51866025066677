@use 'styles/globals/breakpoints';

.block {
    display: flex;
    width: 100%;
    padding: 10rem 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media print{
        display: none !important;
    }

    @media screen and (max-width: breakpoints.$screen-md) {
        margin-top: 4rem;
        padding: 4rem 2rem 2rem 2rem;
    }
}

.gridCntr {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 5rem;
    border-top: 1px solid var(--dove);
}

.textCntr {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.formCntr {
    width: 100%;
    max-width: 500px;
    margin-top: 2rem;
}

