@use 'styles/globals/breakpoints';

.root{
    position: relative;
    height: auto;
    transition: all ease;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        height: auto;
    }
}

.hide{
    visibility: hidden !important;
    height: 0px;
    padding: 0;
    margin: 0;
}


.block{
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
    padding: 4rem 0 2rem 0;
    white-space: nowrap;

    @media only screen and (max-width: breakpoints.$screen-lg ) {
        padding: 3rem 2rem;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.left{
    justify-content: flex-start !important;
}

.listCenter{
    justify-content: center !important;
}


.list{
    display: flex;
    list-style-type: none;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    line-height: 1.2;
    padding: 0;
    margin: 0;
    text-transform: capitalize;
    font-size: 1.4rem;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;

    @media only screen and (max-width: breakpoints.$screen-sm ) {
        // text-overflow: ellipsis;
        // white-space: nowrap;
        max-width: 350px;
        // overflow: hidden;
        // padding-right: 1rem;
        overflow-x: auto;
    }
    
}

.link{
    color: #555;

    @media only screen and (max-width: breakpoints.$screen-sm ) {
		white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
        display: block;
	}
}

.item{
    padding: 0rem;
    line-height: 1.2;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    &::after{
        content: "/";
        padding: 0 1.5rem;
        color: #ccc
    }

    &:last-of-type{
        &::after{
            display: none;
        }
    }


    @media only screen and (max-width: breakpoints.$screen-sm ) {
        max-width: 100px;
        height: 20px;

        &:last-of-type {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}

.preLoaded{
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: row !important;
}
