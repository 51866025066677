@use "styles/globals/placeholders" as *;
@use 'styles/globals/breakpoints';


.dropzone {
    background-color: var(--ice25);
    width: 100%;
    border-radius: 5px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #d3d3d3;
    font-size: 16rem;
    cursor: pointer;
    transition: all 0.3s;
    margin: 1rem 0;

    &:hover {
        border-color: #a9a9a9;
        color: #a9a9a9;
    }

    .addimages {
        display: none;
    }

    .plusicon {
        display: block;
    }
}

.dropzoneActive {
    border: 1px solid var(--ice);
    background-color: var(--ice25);
    color: var(--ocean);
}

.dropzoneText {
    @extend %h3;
    font-size: 14rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dropzoneTextDrag {
    @extend %h3;
    font-size: 2.1rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dropzoneCntr {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    opacity: .35;
}

.dropzoneHelp {
    width: 100%;
    text-align: center;
    display: block;
    margin: 10px 0;
}

.table {
    margin-top: 4rem;
    @extend %tableBlock;

    & tbody {
        @extend %zebraTable;
    }
}

.row {
    @extend %tableRow;
}

.cell {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @extend %tableCell;
}

.headerCell {
    font-weight: 800;
    @extend %tableHeadingCell;
}

.editCell {
    overflow: visible !important;
}

.imageList {
    display: flex;
    margin-top: 20px;
    gap: 10px;
    margin: 1rem 0 2rem;
    width: 100%;

    .thumbnail {
        position: relative;
        width: 100px;
        height: 100px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
        }

        .removeButton {
            position: absolute;
            top: -10px;
            right: -10px;
            background: var(--ocean);
            border: none;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:before {
                content: '×';
                font-size: 18px;
                color: #fff;
            }
        }
    }
}

.removeButton {
    background-color: var(--ocean);
    text-align: center;
    font-size: 12px;
    padding: 1px 6.5px;
    color: #fff;
    margin-left: 15px;
    border-radius: 50%;
}

.fileList {
    background-color: var(--mist);
    padding: 2rem;
    color: var(--ocean);
    text-align: left;
}

.fileList li {
    padding: .8rem 0;
}

.limitReached {
    width: 100%;
    text-align: left;
    color: red;
}

.uploadText {
    text-align: left;
    width: 100%;
}

@media screen and (max-width: breakpoints.$screen-sm) {
    .dropzone {
        background: none !important;
        height: 60px !important;
        align-items: left;
        justify-content: left;
        color: #4c7b9a !important;
        font-size: 2rem !important;
        text-decoration: underline;

        &:hover {
            border-color: #a9a9a9;
            color: #a9a9a9;
        }

        .addimages {
            display: block;
        }

        .plusicon {
            display: none;
        }
    }

}
