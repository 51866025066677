@use 'styles/globals/breakpoints';


.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 0 0 0;
    flex-direction: row;

    @media (max-width: breakpoints.$screen-sm) {
        flex-direction: column;
    }
}

.main{
    min-height: 94dvh;
    height:auto;
}

.footer{
    background-color: var(--dove);
    padding: 2rem;
    width: 100%;
    height: 6dvh;
}


.copyRight{
    display: flex;
    align-items: center !important;
    justify-content: space-between !important;
    flex-direction: row !important;
}

