


.block{
    display: flex;
    align-items: stretch;
    list-style: none;
    justify-content: flex-start;
    overflow-x: auto;
    touch-action: manipulation;
    scroll-padding: 1.6rem;
    scroll-snap-type: none;
    scrollbar-width: none;
    max-width: 1400px;
    scroll-behavior: smooth;
    width: 100%;
}



