@use 'styles/globals/breakpoints';



.block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.wrap{
    flex-wrap: wrap;
    flex-direction: column;
}

.shipType{
    width: 100%;
    display: flex;
    padding-right: 2rem;
    // flex: 1 0 50%;

    &:nth-child(2n){
        padding: 0;
    }

    &:last-child{
        padding-bottom: 2rem;
    }

    
    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100%;
        padding: 0;
        flex: 1 0 0%;
    }
}

.longLeadTime, .lowInventory{
    flex: auto;
    padding-right: 0;
}

.heavyFreight{
    flex: auto;
}


