@use "/components/applications/pip/styles/sharedPip" as *;
@use 'styles/globals/placeholders' as *;


.atcBlock{
    @extend %atcBlock;
}

.atcStack{
    @extend %atcStack;
}

.purchaseButtons{
    @extend %purchaseButtons;
}

.atcBtnBlock{
    @extend %atcBtnBlock;
}

.list{
    margin: 0;
    list-style: none;;
}

.listItem{
    display: block;
    line-height: 1.6;
    font-size: 1.3rem;
}

