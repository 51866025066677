@use 'styles/globals/breakpoints';
@use 'styles/globals/mixins/font';


.block{
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-bottom: 1rem;
}

.disabled{
    cursor: not-allowed;
    opacity: 0.3;
}

.controlGroup{
    padding: 1rem 1rem 1rem 0;
    display: inline-flex;
    width: 80px;
    height: 54px;
    position: relative;
    flex: 1 0 auto;
}

.control{
    appearance: none;
    display: inline-flex;
    position: relative;
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    height: 100%;
    left: -100%;
    inset: 0;
    z-index: 1;
    cursor: inherit;
    vertical-align: middle;
    transition: 0.25s linear background;

    &:focus-visible{
        outline: 2px solid dodgerblue;
        outline-offset: 2px;
    }

    &:focus{
        outline-color: transparent;
    }

    &[disabled]{
        border: none;
        background-color: transparent;
        opacity: 0;
    }

    &::before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 3px;
        z-index: 1;
        background: var(--white);
        height: 30px;
        width: 30px;
        border-radius: 50%;
        box-shadow: 0 1px 5px var(--ocean);
        transform: translate(0, 3px);
        transition: 0.15s linear transform;
    }

    &::after{
        content: "";
        width: 100%;
        height: 100%;
        background-color: var(--dove);
        border-radius: 100px;
    }

    &:checked{
        &::before{
            transform: translate(38px, 2px);
        }

        &::after{
            background-color: var(--ocean);
        }
    }
}

.label{
    line-height: 1.2;
    text-transform: capitalize;
    @include font.dynamic-font(14, 18);

    @media only screen and (max-width: breakpoints.$screen-md ) {
        text-align: left;
    }
}
