.heading{
    display: flex;
    flex-direction: row;
    align-content: center;
}

.image{
    width: 100px;
    height: auto;
    object-fit: contain;
    border-radius: 50%;
    margin-right: 2rem;
}

.logo{
    width: 50%;
}

.content{
    display: flex;
    width: 100%;
    flex-direction: column;
}

