@use "styles/globals/placeholders" as *;

%checkbox-base{
    position: absolute;
    left: 0;
    opacity: 0.01;
}

%label{
    padding-left: 3rem;
    position: relative;
}

%checkbox-aspect{
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: calc(-20px/2);
    width: 20px;
    height: 20px;
    border: 1px solid var(--ocean);
    border-radius: 50%;
    background-color: var(--ocean);
    -webkit-transition: all .275s;
    transition: all .275s;
    z-index: 1;
}

%checkmark{
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' fill='%234c7b9a' viewBox='0 0 24 24'%3E%3Cpath d='M12 20.016c4.406 0 8.016-3.609 8.016-8.016s-3.609-8.016-8.016-8.016-8.016 3.609-8.016 8.016 3.609 8.016 8.016 8.016zM12 2.016c5.531 0 9.984 4.453 9.984 9.984s-4.453 9.984-9.984 9.984-9.984-4.453-9.984-9.984 4.453-9.984 9.984-9.984zM12 6.984c2.766 0 5.016 2.25 5.016 5.016s-2.25 5.016-5.016 5.016-5.016-2.25-5.016-5.016 2.25-5.016 5.016-5.016z'%3E%3C/path%3E%3C/svg%3E");
    -webkit-transition: all .2s;
    transition: all .2s;
    left: -5px;
    width: 30px;
    height: 30px;
    display: block;
    z-index: 2;
    top: 50%;
    margin-top: -15px;
}


.block{
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    width: 100%;
    padding: 1rem 0;
}

.control{
    &:not(:checked){
        @extend %checkbox-base;

        & + label{
            @extend %label;

            &::before{
                @extend %checkbox-aspect;
                background-color: var(--white);
            }

            &::after{
                @extend %checkmark;
                opacity: 0;
                transform: scale(0);
            }
        }
    }

    &:checked{
        @extend %checkbox-base;

        & + label{
            @extend %label;

            &::before{
                @extend %checkbox-aspect;
                border-color: var(--white);
                background-color: var(--white);

            }

            &::after{
                @extend %checkmark;
                opacity: 1;
                transform: scale(1);
            }
        }
    }

    &:disabled:not(:checked) + label, &:disabled:checked + label{
        cursor: not-allowed;
    }

    &:disabled:not(:checked) + label::before, &:disabled:checked + label::before{
        background-color: var(--mist);
    }

    &:not(:checked):focus + label::before, &:checked:focus + label::before{
        box-shadow: inset 0 1px 3px var(--ocean), 0 0 0 6px var(--ice)
    }


    // position
    &_right{

        & + label{
            padding-right: 3rem;
            padding-left: 0 !important;

            &::before{
                left: auto !important;
                right: 0;   
            } 

            &::after{
                left: auto !important;
                right: -6px;   
            } 
        }
    }

}


.disabled{
    cursor: not-allowed;
    opacity: 0.3;
}

.label{
    line-height: 1.15;
    margin-bottom: 0;
    text-transform: capitalize;
    flex: 1 0 auto;
    display: flex;
    width: 100%;
    cursor: pointer;
}

