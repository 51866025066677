@use 'styles/globals/breakpoints';
@use 'styles/globals/mixins/font';


.block{
    display: flex;
    flex-direction: column;
    //padding: 5rem 0;
    position: relative;
    margin: 2rem 0 10rem 0;
}

.row{
    display: flex !important;
    justify-content: space-evenly !important;
    flex-wrap: wrap;
    flex-direction: row !important;
    width: 100%;

    & > *{
        width: 100%;
        max-width: 100%;


        // @media only screen and (max-width: breakpoints.$screen-lg ) {
        //     max-width: 33.333%;
        //     width: 33.333%;
        //     flex: 1 0 33.333% !important;
        // }

    
        @media only screen and (max-width: breakpoints.$screen-lg ) {
            max-width: 50%;
            width: 50%;
            flex: 1 0 50% !important;
        }
    }


    @media only screen and (min-width: breakpoints.$screen-lg ) {
        justify-content: center;
    }

    @media only screen and (max-width: breakpoints.$screen-md ) {
        justify-content: center;
    }

    @media only screen and (max-width: breakpoints.$screen-xs ) {
        flex-direction: row;
        justify-content: flex-start;
    }
}

.item{
    flex: 1 0 0%;
    max-width: 400px;
}

.background{
    position: absolute;
    width: 100vw;
    top: 0;
    left: -10vw;
    z-index: -1;

    @media only screen and (max-width: breakpoints.$screen-md) {
        left: 0;
    }
}

.calloutLink{
    border: none !important;
    padding: 0;
    align-self: center;
    display: flex;
    align-items: flex-end;
    @include font.dynamic-font(16, 22);

    @media only screen and (max-width: breakpoints.$screen-xl) {
        height: 46px;
    }

    @media only screen and (max-width: breakpoints.$screen-md) {
        height: 46px;
    }

    @media only screen and (max-width: breakpoints.$screen-xs ) {
        padding: 0;
        text-align: center;
        margin: 0;
        height: 45px;
    }

}

