@use 'styles/globals/mixins/font';
@use 'styles/globals/breakpoints';


.block{
    display: flex;
    align-items: center;
    margin-top: 2rem;
}

.imageCntr{
    display: block;
    margin: 0;
}

.image{
    margin-right: 4rem;
    width: 100%;
    object-fit: contain;
}

.textCntr{
    margin: 0 0 0 3rem;
    line-height: 1.4;

    @media screen and (max-width: breakpoints.$screen-md){
        margin-left: 2rem;
    }
}

.productName{
    @include font.dynamic-font(18, 21);
    color: inherit;
    border: none;
    text-decoration: none;
    font-weight: 600;

    &:hover{
        color: inherit;
        opacity: 1;
        border-color: inherit;
    }
}

.brandName{
    display: block;
    line-height: 1;
}

.detail{
    width: 100%;
    display: block;
    font-size: 0.9rem;
    color: #777;
    margin-top: 0.25rem;
}

