@use 'styles/globals/placeholders' as *;

.block{
    position: relative;
    width: 100%;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;

    &__select{
        &::after{
            @extend %dark-arrow;
            transform: rotate(90deg);
            right: 1.5rem;
        }
    }
}

.error{
    &__label{
        color: var(--terracotta) !important;
    }

    &__control{
        border-color: var(--terracotta) !important;
    }

    &__help{
        color: var(--terracotta) !important;
    }
}


.label{
    @extend %standard-control-label;
}

.controlGroup{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.control{
    @extend %standard-control;
}

.active{
    @extend %standard-active-control;
 }

.help{
    @extend %standard-control-help-text;
}
