
%caps{
    content: "";
    height: 12px;
    width: 2px;
    background-color: var(--charcoal);
    display: block;
    position: absolute;
    top: -5px
}

.block{
    box-sizing: content-box;
	height: 2px;
	border: none;
    background-color: var(--dove);
    margin: 0;
    flex-shrink: 0;
}

.spacer{
    width: 100%;
    display: flex;
    margin: 0;
    flex-shrink: 0;
    visibility: hidden;
}

.capped{
    height: 2px;
    background-color: var(--charcoal);
    position: relative;

    &::after{
        @extend %caps;
        right: 0;
    }

    &::before{
        @extend %caps;
        left: 0;
    }
}

// color
.color{
    &__light{
        background-color: var(--mist);
    }

    &__dark{
        background-color: var(--charcoal);
    }
}


// Orientation
.vertical{
    width: 1px;
    height: 100%;
}

// 
.flex{
    height: auto;
    width: 2px;
    align-self: stretch;
}


.or{
    color: #777;
    display: flex;
    padding: 2rem 0;
    position: relative;
    width: 100%;
    line-height: 1;
    font-size: 1.4rem;
    text-align: center;
    align-self: center;
    justify-content: center;
    max-width: 500px;

    & span{
        background-color: var(--ice25);
        padding: 0 1rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }

    &::before{
        content: "";
        position: absolute;
        width: 50%;
        height: 0px;
        border: .5px solid #ccc;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
}


.whiteOr{
    & span{
        background-color: var(--white);
    }
}

