@use 'styles/globals/placeholders' as *;


.block{
    display: flex;
    align-items: flex-start;
    padding: 2rem 0 2rem 0;
    margin-top: 1rem;

    & svg{
        margin-top: -8px;
    }
}

.heading{
   @extend %shipping_heading;
}

.icon{
    @extend %shipping_icon;
}

