@use 'styles/globals/breakpoints';

.block{
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    width: 100%;

    @media only screen and (max-width: breakpoints.$screen-md) {
        display: flex;
        flex-direction: column;
        padding: 0 2rem;
    }
}

.brick{
    width: 100%;
    padding: 0 1rem;
    position: relative;

    &:first-of-type{
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
        padding-left: 0;
    }

    &:nth-of-type(2){
        grid-column-start: 3;
        grid-row-start: 1;
        grid-row-end: 2;
        padding-right: 0;
    }

    &:nth-of-type(3){
        grid-column-start: 3;
        grid-row-start: 2;
        grid-row-end: 3;
        padding-right: 0;
    }

    @media only screen and (max-width: breakpoints.$screen-md) {
        padding: 0;
    }
}

.figure{
    width: 100%;
    position: relative;
    margin: 0;
}

.image{
    width: 100%;
    object-fit: contain;
    height: auto;
}

.cta{
    line-height: 2.4;
    text-decoration: underline;
}
