%stepLine{
    content: "";
    position: absolute;
    top: 22px;
    z-index: -1;
    height: 2px;
}

.block{
    position: relative;
    display: flex;
    width: 100%;
    list-style-type: none;
    margin: 0;

    &:after{
        @extend %stepLine;
        left: 0;
        width: 15%;
        background-color: #fff;
    }

    &:before{
        @extend %stepLine;
        width: 100%;
        background-color: #eee;
    }
}

.step{
    position: relative;
    width: 100%;
    text-align: center;
    color: #aaa;
    line-height: 1.2;

    &:last-child{
        &:after{
            @extend %stepLine;
            right: 0;
            left: initial;
            width: 50%;
            background-color: #fff;
        }
    }

    &:before{
        content: "";
        border: 10px solid #fff;
        background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 24 24' version='1.1' viewBox='0 0 24 24' fill='%23aaa' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1c-6.1 0-11 4.9-11 11s4.9 11 11 11 11-4.9 11-11-4.9-11-11-11zM12 21c-5 0-9-4-9-9s4-9 9-9c5 0 9 4 9 9s-4 9-9 9z'%3E%3C/path%3E%3C/svg%3E");
        display: flex;
        margin: 0 auto;
        border-radius: 100%;
        width: 45px;
        height: 45px;
        background-color: #fff;
        fill: #ccc;
    }

    &:after{
        @extend %stepLine;
        left: 50%;
        width: 0%;
        transition: all 750ms ease-in;
        background: #eee;
    }
}

.active{
    color: #111;

    &:before{
        background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 24 24' version='1.1' viewBox='0 0 24 24' fill='%23111' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1c-6.1 0-11 4.9-11 11s4.9 11 11 11 11-4.9 11-11-4.9-11-11-11zM12 21c-5 0-9-4-9-9s4-9 9-9c5 0 9 4 9 9s-4 9-9 9z'%3E%3C/path%3E%3C/svg%3E");
    }
}

.complete{
    color: #2ecc71;

    &:last-child:after{
        animation: none;
    }
    
    &:after{
        background: #2ecc71;
        animation: nextStep 750ms;
        animation-fill-mode: forwards;
    }

    &:before{
        background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 24 24' version='1.1' viewBox='0 0 24 24' xml:space='preserve' fill='%232ecc71' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 10.1c-0.6 0-1 0.4-1 1v0.9c0 5-4 9-9 9 0 0 0 0 0 0-5 0-9-4-9-9s4-9 9-9c0 0 0 0 0 0 1.3 0 2.5 0.3 3.7 0.8 0.5 0.2 1.1 0 1.3-0.5s0-1.1-0.5-1.3c-1.4-0.6-2.9-1-4.5-1 0 0 0 0 0 0-6.1 0-11 4.9-11 11s4.9 11 11 11c0 0 0 0 0 0 6.1 0 11-4.9 11-11v-0.9c0-0.6-0.4-1-1-1z'%3E%3C/path%3E%3Cpath d='M9.7 10.3c-0.4-0.4-1-0.4-1.4 0s-0.4 1 0 1.4l3 3c0.2 0.2 0.4 0.3 0.7 0.3s0.5-0.1 0.7-0.3l10-10c0.4-0.4 0.4-1 0-1.4s-1-0.4-1.4 0l-9.3 9.3-2.3-2.3z'%3E%3C/path%3E%3C/svg%3E");
    }
}


@keyframes nextStep {
    0% { width: 0%; }
    100% { width: 100%; }
}


