@use 'styles/globals/breakpoints';

.hotspot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute !important;
    width: 56px;
    height: 56px;
    cursor: pointer;

    @media only screen and (max-width: breakpoints.$screen-sm) {
        width: 35px;
        height: 35px;
    }
}

.hotspotWrapper {
    position: relative;
}

.iconHotspot {
    opacity: 1;
    transition: opacity 0.3s ease;
    z-index: 1;
}

.iconHotspotHover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 1; /* Adjust as needed */
}


.hotspot:hover .iconHotspot {
    opacity: 0;
}

.hotspot:hover .iconHotspotHover {
    display: block;
    opacity: 1;
}


.hotspotText {
    display: none;
    position: absolute;
    top: -100%; /* Adjust as necessary */
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    background-color: var(--ice);
    clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 55% 75%, 50% 100%, 45% 75%, 0% 75%);
    color: var(--ocean);
    padding: 5px 10px 20px 10px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    max-width: max-content !important;
    text-align: center;
    font-size: 18px;
}

.hotspot:hover .hotspotText {
    display: block;
}



