
.header{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.headerTitle{
    text-align: center;
    margin-bottom: 3rem;
}

.lookingFor{
    background-color: var(--ice);
    padding: 5rem 0
}
