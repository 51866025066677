@use "styles/globals/placeholders" as *;


$loader-size: 23px;
$check-height: 23px;
$check-width: calc($loader-size/2);
$check-left: calc($loader-size/6) + calc($loader-size/12);
$check-thickness: 3px;
$check-color: #fff;

.block {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.spinner {
	width: 20px;
	height: $loader-size;
	animation: spin 0.75s linear infinite;
}

.largeSpinner{
	width: 50px;
	height: 50px;
}

.greenCheckmark{
	&.draw:after{
		border-color: var(--green) !important;
	}
}

.checkmark {
	height: $loader-size;
	width: $loader-size;
	position: relative;

	&.draw:after {
		height: $loader-size;
		width: $check-width;
		animation-duration: 800ms;
		animation-timing-function: ease;
		animation-name: checkmark;
		transform: scaleX(-1) rotate(135deg);
		border-color: $check-color;
		content: "";
	}

	&:after {
		opacity: 1;
		height: $loader-size;
		width: $check-width;
		transform-origin: left top;
		border-right: $check-thickness solid $check-color;
		border-top: $check-thickness solid $check-color;
		content: "";
		left: $check-left;
		top: 50%;
		left: 0;
		position: absolute;
	}
}

.error {
	display: flex;
	justify-content: center;
	width: $check-width;
	height: $loader-size;
	animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  	transform: translate3d(0, 0, 0);

	&::after{
		content: "×";
		font-size: 5rem;
		display: flex;
		line-height: 0.1;
		position: absolute;
		top: 6px;
	}
}


@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}


@keyframes checkmark {
	0% {
		height: 0;
		width: 0;
		opacity: 0;
	}
	20% {
		height: 0;
		width: $check-width;
		opacity: 0.55;
	}
	40% {
		height: $check-height;
		width: $check-width;
		opacity: 0.75;
	}
	100% {
		height: $check-height;
		width: $check-width;
		opacity: 1;
	}
}

@keyframes shake {
	10%, 90% {
		transform: translate3d(-1px, 0, 0);
	  }
	  
	  20%, 80% {
		transform: translate3d(2px, 0, 0);
	  }
	
	  30%, 50%, 70% {
		transform: translate3d(-4px, 0, 0);
	  }
	
	  40%, 60% {
		transform: translate3d(4px, 0, 0);
	  }
}
