@use 'styles/globals/placeholders' as *;

.block{
    margin: 0;
    position: relative;
    width: 100%;
}

.error{
    &__label{
        color: var(--terracotta) !important;
    }

    &__control{
        border-color: var(--terracotta) !important;
    }

    &__help{
        color: var(--terracotta) !important;
    }
}

.label{
    position: relative;
    margin: 0;
    font-size:17px !important;
    color: var(--ocean);
    top: 0px;
    padding: 0 10px;
    z-index: 1;
    display: flex;

}

.control{
   margin: 0;
}

.wrapper {
 background-color: #fff;
 padding: 1rem;
}

