
.block{
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;
}

.overlay{
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.darken{
    filter: brightness(50%);
}

.image{
    object-fit: contain;
}