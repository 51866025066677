@use 'styles/globals/breakpoints';

// Pod Slider
.block{
    display: inline-block;
    margin: 0;
    //padding: 0 4rem;
    max-width: var(--standard-width);
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    position: relative;

    @media only screen and (max-width: breakpoints.$screen-md) {
        padding: 4rem 0 0 0;
        max-width: 100vw;
        width: 100%;
    }
}

.hidden{
    opacity: 0 !important;
    height: 450px !important;
    overflow: hidden;
}

.body{
    display: flex;
    flex-direction: row;
    width: auto;
}

.sliderTitle{
    text-align: center;
    margin-left: 1rem;
    width: 100%;

    @media only screen and (max-width: breakpoints.$screen-md) {
        margin-left: 0;
        //text-align: center !important;
    }
}

.container{
    display: flex;
    width: 100%;

    @media only screen and (max-width: breakpoints.$screen-md) {
        padding: 0 20px;
    }
}


.navigateBtn{
    background-color: transparent;
    line-height: 1;
    display: flex;
    align-items: center;
    width: 3rem;
    position: absolute;
    top: 50%;

    @media only screen and (max-width: breakpoints.$screen-md) {
        display: none;
    }

    & svg{
        fill: var(--indigo);
    }

    & svg:hover {
        fill: var(--ocean);
    }

    &:disabled{
        opacity: .25;
        cursor: not-allowed;
    }
}

.left{
    left: -35px;
}

.right{
    right: -35px;
}

.dotBlock{
    display: none;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 100%;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        display: flex;
    }
}

.dot{
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #cccccc;
    padding:.5rem;
    margin: 0 .5rem;
    cursor: pointer;
}

.dotActive{
    background-color: var(--indigo);
}

