@use 'styles/globals/breakpoints';

.block {
    border: 1px solid #ccc;
    border-radius: 20px;
    opacity: 1;
    padding: 1rem 2rem;
    background-color: var(--dove);
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    color: inherit !important;
}

.text {
    font-size: 1.2rem;
    padding-right: .75rem;
    line-height: 1;
    text-transform: capitalize;
    word-break: keep-all;
}

a.text,
a.block:hover {
    text-decoration: none;
    cursor: pointer;
}

.tagLabel {
    display: none !important;

    @media only screen and (max-width: breakpoints.$screen-lg) {
        display: inline-block !important;
    }
}



