@use 'styles/globals/placeholders' as *;



.highlightedResults{
    margin-bottom: 6rem;

    em{
        font-weight: bold;
    }
}

.results{
    margin: 0
}

.title{
    margin-bottom: 1.5rem;
    color: var(--link_color);
}

.category{
    display: block;
    font-weight: normal;
    padding-bottom: .5rem;
}

.resultsSkeleton{
    margin-top: 1.5rem !important;
}

