@use 'styles/globals/placeholders' as *;
@use 'styles/globals/breakpoints';


.block{
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
}

.list{
    margin: 0 2rem;
    padding: 0;

    @media screen and (max-width: breakpoints.$screen-md){
       margin: 0
    }
}

.logos{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
}

.logo{
    width: auto;

    @media screen and (max-width: breakpoints.$screen-sm){
        width: 125px;
    }
}

.plus{
    margin: 0 1rem;

    @media screen and (max-width: breakpoints.$screen-sm){
        margin: 0 0.5rem;
        width: 20px;
        height: 20px;
    }
}

.header{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center
}

.heading{
    @extend %h3;
    text-align: center;
    margin-bottom: 4rem;
    font-size: 1.2rem;
    width: 75%;

    @media screen and (max-width: breakpoints.$screen-md){
        width: 100%;
    }
}

.term{
    @extend %h5;
    font-weight: 600;
    margin-bottom: 0.5rem;
    line-height: 1;
}

.description{
    font-size: 1.4rem;
    line-height: 1.4;
    font-weight: normal;
    text-transform: none;
    letter-spacing: normal;
}

.item{
    margin-bottom: 2rem;
    display: inline-block;
    width: 100%;
}

.itemIcon{
    float: left;
    margin: 0 2rem 0 0;
    object-fit: contain;
}

.footer{
    width: 100%;
    display: flex;
    justify-content: center;
}

.footerLink{
    font-size: 1.4rem;
    font-weight: 600;
    text-decoration: underline;
}

