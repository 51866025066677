@use 'styles/globals/breakpoints';

.top90 {
    top: 90px !important;
}

.wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
}


.homeGridColumn{
    position: relative;
    margin-top: 4rem;
}

.accountNav{
    display: flex;
    flex-direction: column;
    background-color: var(--mist);
    border-radius: var(--border-radius);
    padding: 2rem 4rem 0 0rem;
    border-top-right-radius: 0;
}


.content{
    display: flex;
    flex-direction: column;
    padding: 7rem 4vw 0 6rem;
    box-shadow: var(--box-shadow-lg);
    border-radius: var(--border-radius);
    border-top-left-radius: 0;
}

.header{
    display: flex;
    justify-content: space-between;
}

.headerList{
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
}

.headerListItem{
    margin-right: 4rem;
    font-size: 1.6rem;
    line-height: 1.3;
}

.hidePwd {
    display: none !important;
}

@media (max-width: breakpoints.$screen-md){
    .homeGrid {
        display: grid;
    }
}
@media (max-width: breakpoints.$screen-sm) {
    .homeGrid {
            display: flex;
        }
    .content{
        padding: 10px;
    }

    .headerListItem{
        margin-right: 2.5rem !important;
        font-size: 1.2rem !important;
        line-height: 1.3;
    }

    .header{
        display: block !important;
    }
    .allOrdersLink{
        font-size: 12px !important;
    }
    .detailsRow{
        display: block !important;
    }
    .detailsBody ul{
        margin-left: 0px;
    }

    .accountNav{
        display: none;
    }
    .profileFormWrapper{
        width: 100%;
        padding: 0px !important;

        &>div{
            width: 100%;
            padding: 0px !important;
        }
    }
    .changePasswordWrapper{
        margin-top: 20px !important;
    }
    .headerCard{
        margin-bottom: 0px !important;
        padding: 2rem !important;
    }

    .formWrapper{
        margin: 0px !important;

        &>fieldset > div{
            width: 100%;
            padding-right: 0px;
        }
    }

    .switchWrapper{
        width: 42% !important;
    }

    .headerTitle{
        a{
            display: block;
            width: fit-content;
            margin: 0px !important;
        }
    }
}

.detailsBody{
    display: flex;
    flex-direction: column;
    padding: 4rem;
    margin: 2rem 0 0 0;
    border: 1px solid #ddd;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    width: 100%;
}

.detailList{
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    width: 250px;
    padding: 0;

    & dd, dt{
        width: 50%;
    }

    & dd{
        text-align: right;
    }
}

.detailsRow{
    display: flex;
    justify-content: space-between;
}

.invalidInput {
    color:#db613a;
    position: relative;
}
.invalidInput label {
    color: #db613a;
}
.invalidInput input{
    border-bottom-color: #db613a;
    color: #db613a;
}
.invalidInput p{
            font-size: 1.3rem;
            position:absolute;
            left: 10px;
            top: 55px;
}

/*resources css*/
.resourcesAccordion {
    background-color: #F0F4F7 !important;
    margin-bottom: 4rem;
 
}
.resourcesAccordion > div {
    border-bottom: 1px solid #7A7D85 !important;
    padding-bottom: 2rem;
    background-color: #F0F4F7 !important;
}
.resourcesAccordion >div:last-of-type {
    border-bottom: 0px !important;
}
.resourcesAccordion button{
    background-color: #F0F4F7 !important;
    justify-content: center !important;
}
  .resourcesAccordion div[class*="TextImage_text"] {
       padding-left: 0 !important;
    background-color: #F0F4F7 !important;
   }
   .resourcesAccordion div[class*="TextImage_container"] {
    background-color: #F0F4F7 !important;
    padding-bottom: 1rem;
   }
.resourcesAccordion div[class*="TextImage_accordionImage"]{ 
    width: 557px !important;
    height: 313px !important;
    background-color: #F0F4F7 !important;
}
@media (max-width: breakpoints.$screen-sm) {
    .resourcesAccordion div[class*="TextImage_text"] {
        width: 100% !important;
        text-align: center !important;
        padding-bottom: 0 !important;
    }
}

