@use 'styles/globals/breakpoints';

.block{
    display: flex;
    flex-direction: column;
    padding: 4rem;
    margin-bottom: 6rem;
    border: 1px solid #ddd;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.image{
    width: 100%;
    object-fit: contain;
}


.productList{
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.productListItem{
    margin-right: 2rem;

    &:last-of-type{
        margin: 0
    }
}


@media (max-width: breakpoints.$screen-sm) {
    .editButton{
        min-width: 96px;
    }
}
