@use "styles/globals/placeholders" as *;
@use 'styles/globals/mixins/font';
@use "/components//applications/pip//styles/sharedPip" as *;
@use 'styles/globals/breakpoints';



.atcBlock{
    @extend %atcBlock;

    & [class^="QuantityButton_block"]{
        height: 55px;
    }

    &:has(.priceHiddenLabel){
        max-height: none;
    }
}

.atcStack{
    @extend %atcStack;
}

.contactBtn{
    white-space: nowrap;
    font-weight: 800;
}

.designConsult{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4rem;

    @media only screen and (max-width: breakpoints.$screen-lg ) {
        flex-direction: column;
    }
}

.priceHiddenLabel{
    box-shadow: var(--box-shadow-sm);
    padding: 1rem 2rem;
    background-color: var(--white);
    border-radius: var(--border-radius);
    width: 100%;
    text-transform: none;
    margin-bottom: 2rem;
    display: flex;
    height: auto;
}

.consultItem{
    display: flex;
    align-items: center;
    line-height: 1.2;
    margin-bottom: 1rem;
    width: 50%;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100%;
        margin-bottom: 4rem;

        &:last-of-type{
            margin: 0;
        }
    }
}

.consultText{
    margin: 0;
    text-align: left;
    width: 100%;

    @media only screen and (max-width: breakpoints.$screen-sm ) {
        text-align: left;
    }
}

.consultCtaCntr{
    display: flex;
    width: 50%;
    flex: 1 0 auto;
    margin-left: 2rem;
    justify-content: flex-end;
    flex-direction: column;

    &:first-of-type{
        margin-left: 0;
    }

    @media only screen and (max-width: breakpoints.$screen-lg ) {
        justify-content: flex-start;
        width: 100%;
        margin: 2rem 0 0 0;
    }

    @media only screen and (max-width: breakpoints.$screen-sm ) {
        flex-wrap: wrap;

        & button{
            margin: 1rem 0 !important;
            width: 100%;
        }
    }
}


.btnLink{
    background-color: transparent;
    color: var(--link_color);
    border-bottom: 1px solid var(--link_color);
}


.purchaseButtons{
    @extend %purchaseButtons;
}

.buyNow{
    filter: brightness(1);
}


.outOfStock{
    @extend %standard-button;
    margin-left: 2rem;
    position: relative;
    overflow: hidden;
    max-width: 500px;
    height: 55px;
    background-color: var(--dove);
    color: #777;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
}

