@use 'styles/globals/breakpoints';


.header{
    padding: 0 0 4rem 0
}

.heading{
    text-align: center;
    margin: 0;
}

.meta{
    background-color: var(--ice50);
    padding: 4rem;
    margin-right: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        margin: 0 2rem;
        padding: 2rem;
    }

}

.metaList{
    margin: 0 0 6rem 0;
    width: 100%;
}

.mapCntr{
    margin: 0;
    padding: 0;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        padding: 2rem;
    }
}

.metaCntr{
    justify-content: center;
}

.title{
    margin: 0;
    display: flex;
    align-content: center;
    align-items: center;
}

.icon{
    margin-right: 2rem;
}

.description{
    margin: .5rem 0 4rem 0;
    line-height: 1.3;
    padding-left: 5rem;

    &:last-of-type{
        margin-bottom: 0;
    }
}

.directionsCntr{
    padding: 0;
    text-align: center;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        padding: 2rem;
        text-align: left;
    }
}
