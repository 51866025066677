.underlay{
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(102, 102, 102, 0.75);
    z-index: 99;
    display: flex;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    animation-name: overlay-opening-keyframes;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.takeover{
    z-index: 9999;
}


