@use 'styles/globals/breakpoints';

.list {
    margin: 1rem 0 0 0;
    display: flex;
    width: 100%;
    list-style-type: none;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    touch-action: manipulation;
    scroll-padding: 1.6rem;
    scroll-snap-type: none;
    scrollbar-width: none;
    max-width: 1400px;
    scroll-behavior: smooth;

    @media only screen and (max-width: breakpoints.$screen-md) {
        justify-content: flex-start;
        align-items: center;
    }
}

.item {
    margin: .5rem;
    display: flex;
    flex-direction: row;
    width: auto;
    flex: 0 0 auto;

    &:first-of-type{
        margin-left: 0;
    }

    @media only screen and (max-width: breakpoints.$screen-md) {
        flex: 1 0 auto;
    }
}

.itemCntr{
    list-style-type: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    flex: 1 0 auto;

    @media only screen and (max-width: breakpoints.$screen-md) {
        padding: 1rem;
    }
}

.tagLabel {
    display: none !important;
}

@media only screen and (max-width: breakpoints.$screen-lg) {
    .tagLabel {
        display: block !important;
        width: 100%;
    }
}

.clear{
    display: block;

    @media only screen and (max-width: breakpoints.$screen-md) {
        display: none;
    }
}

.label{
    text-transform: uppercase;
}

