

.block{
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    background-color: var(--white);
    box-shadow: var(--box-shadow-sm);
    margin: 2rem;
}

.header{
    padding: 2rem;
    background-color: var(--white);
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close, .back{
    background-color: transparent;
    padding: .5rem;
}

.back{
    margin-right: 1rem;
}

.controls{
    display: flex;
    flex-direction: row;
    align-items: center;
}
